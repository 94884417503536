import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormUtils } from '@foxeet/utils/functions';
import { PopoverController } from '@ionic/angular';
import { takeUntil } from 'rxjs/operators';
import { BaseFormMobileComponent } from '../../../base-components';
import { SearchableListComponent } from '../../../general-components/searchable-list/mobile/searchable-list.component';
import { SearchableListModel } from '@foxeet/domain';

@Component({
  selector: 'ui-mobile-form-input-searcher',
  templateUrl: './form-input-searcher.component.html',
  styleUrls: ['./form-input-searcher.component.scss'],
})
export class FormInputSearcherComponent extends BaseFormMobileComponent implements OnInit, OnChanges {
  public searcherLabel = '';

  @Input() config!: SearchableListModel;
  @Input() items!: unknown[];

  @Output() inputEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private readonly _popoverController: PopoverController, private _translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.control.valueChanges.pipe(takeUntil(this._componentDestroyed)).subscribe((el) => {
      if (this.items && this.items.length) {
        this._setSearcherData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if ((changes.items?.currentValue.length || this.items.length) && (!!changes.config?.currentValue || this.config)) {
      // TODO: Intentar sustituir, Ahora mismo es necesario ya que este ngOnChanges salta antes de que el control se haya creado.
      setTimeout(() => this._setSearcherData(), 500);
    }
  }

  public async onInputSearchBar() {
    const popover = await this._popoverController.create({
      component: SearchableListComponent,
      componentProps: {
        items: this.items,
        config: this.config,
        controlValue: this.control.value,
      },
    });

    popover.onDidDismiss().then((popoverResult) => {
      const result = popoverResult.data;
      this.control.markAsTouched();

      if (result) {
        if (Array.isArray(result)) {
          this.control.setValue(result.map((el) => el[this.config.value]));
          this.searcherLabel = result.map((el) => this._translateService.instant(el[this.config.label])).join(', ');
        } else {
          this.control.setValue(result[this.config.value]);
          this.searcherLabel = this._translateService.instant(result[this.config.label]);
        }

        this.inputEmitter.emit(result);
        this.control.markAsDirty();
      } else {
        this.control.markAsTouched();
        this.control.updateValueAndValidity();
      }
    });

    popover.present();
  }

  private _setSearcherData(): void {
    const control = this.form.get(this.controlName) as FormControl;

    if (control && !FormUtils.isEmpty(control.value) && this.items && this.items.length && this.config) {
      if (Array.isArray(control.value)) {
        this.searcherLabel = control.value
          .map((el) => {
            const searcherItem = this.items.find((item: any) => el === item[this.config.value]);
            return this._translateService.instant(searcherItem[this.config.label]);
          })
          .join(', ');
      } else {
        const searcherItem = this.items.find((item: any) => control.value === item[this.config.value]);
        this.searcherLabel = this._translateService.instant(searcherItem[this.config.label]);
      }
    }
  }
}
